<template>
  <DataTable :options="options" />
</template>

<script>
import { TAG_TYPES } from '@tutti/constants';

export default {
  data() {
    return {
      options: {
        addNew: true,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Type', value: 'type', capitalize: true, filter: { type: 'autocomplete', items: TAG_TYPES } },
        ],
        action: {
          hideView: true,
        },
      },
    };
  },
};
</script>
